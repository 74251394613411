import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require('../locales/en/translation.json'),
    },
    hi: {
      translation: require('../locales/hi/translation.json'),
    },
    te: {
      translation: require('../locales/te/translation.json'),
    },
    od: {
      translation: require('../locales/od/translation.json'),
    },
    ka: {
      translation: require('../locales/ka/translation.json'),
    },
    ta: {
      translation: require('../locales/ta/translation.json'),
    },
    ma: {
      translation: require('../locales/ma/translation.json'),
    },

  },
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language if the current language translation is missing
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
