import React, { useEffect } from "react";

import { Box, Container, Link, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import "./i18n";

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();

// const getRandomColor = () => {
//   const letters = '0123456789ABCDEF';
//   let color = '#';
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// };

export default function Languages() {
  // const [buttonColors, setButtonColors] = useState({ en: '#FFFFFF', hi: '#FFFFFF', te: '#FFFFFF' });

  // useEffect(() => {
  //   setButtonColors({
  //     en: getRandomColor(),
  //     hi: getRandomColor(),
  //     te: getRandomColor(),
  //   });
  // }, []);

  const { i18n } = useTranslation();

  const changeLanguage = (lang, event) => {
    event.preventDefault();
    i18n.changeLanguage(lang);
    sessionStorage.setItem("selectedLanguage", lang);
    // No need to reload the page
  };

  useEffect(() => {
    // Check if a language is saved in sessionStorage and apply it
    const savedLanguage = sessionStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 8 },
        pb: { xs: 8, sm: 8 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", sm: "flex" },
          flexDirection: "column",
          width: "100%",
          gap: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: "medium", color: "text.secondary" }}
        >
          Read this page in your own language
        </Typography>

        <Box sx={langContentStyle}>
          <div style={langContentStyle}>
            <div>
              <Link
                sx={languageStyle}
                href="#en"
                onClick={(event) => changeLanguage("en", event)}
              >
                English
              </Link>{" "}
              <Link
                sx={languageStyle}
                href="#hi"
                onClick={(event) => changeLanguage("hi", event)}
              >
                हिंदी
              </Link>{" "}
              <Link
                sx={languageStyle}
                href="#te"
                onClick={(event) => changeLanguage("te", event)}
              >
                తెలుగు
              </Link>
              <Link
                sx={languageStyle}
                href="#od"
                onClick={(event) => changeLanguage("od", event)}
              >
                ଓଡିଆ
              </Link>
              <Link
                sx={languageStyle}
                href="#od"
                onClick={(event) => changeLanguage("ka", event)}
              >
                ಕನ್ನಡ
              </Link>
              <Link
                sx={languageStyle}
                href="#od"
                onClick={(event) => changeLanguage("ta", event)}
              >
                தமிழ்
              </Link>
              <Link
                sx={languageStyle}
                href="#od"
                onClick={(event) => changeLanguage("ma", event)}
              >
                മലയാളം
              </Link>
            </div>
          </div>
        </Box>
      </Box>
    </Container>
  );
}

const langColor = {
  color: "text.primary",
};

const langContentStyle = {
  textAlign: "center",
  gap: 1,
};

const languageStyle = {
  ...langColor,
  mx: 1,
  cursor: "pointer",
  textDecoration: "none",
  "&:hover": { textDecoration: "underline" },
};
