import * as React from 'react';
import Divider from '@mui/material/Divider';
import Hero from './components/Hero';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Languages from './components/Languages';
import BuyNow from './components/BuyNow';
import ProductDetails from './components/ProductDetails';
import LaunchingSoon from './components/LaunchingSoon';


export default function MainPage() {
 
  return (
    <div>
      <Hero />
      <div>
        
        <LaunchingSoon />

        <Divider />
        <ProductDetails />
        <Divider />
        <BuyNow/>
        <FAQ />
        <Divider />
        <Languages/>
        <Divider />
        <Footer />
      </div>
    </div>
  );
}
