
import React from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import getMPTheme from '../theme/getMPTheme';
import NavBar from "../NavBar";

const ThemeProviderComponent = ({ children }) => {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    // const savedMode = localStorage.getItem("themeMode");
    // if (savedMode) {
    //   setMode(savedMode);
    // } else {
    //   // If no preference is found, it uses system preference
    //   const systemPrefersDark = window.matchMedia(
    //     "(prefers-color-scheme: dark)"
    //   ).matches;
    //   setMode(systemPrefersDark ? "dark" : "light");
    // }
    setMode("dark");
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode); // Save the selected mode to localStorage
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <CssBaseline />
      <NavBar
        toggleCustomTheme={toggleCustomTheme}
        showCustomTheme={showCustomTheme}
        mode={mode}
        toggleColorMode={toggleColorMode}
      /> 
      {children}
    </ThemeProvider>
  );
};

export default ThemeProviderComponent;
