import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import MainPage from "./MainPage";
import FeedbackForm from "./components/FeedbackForm";
import FeedbackSuccessPage from "./components/FeedbackSuccessPage";
import ThemeProviderComponent from "./components/ThemeProviderComponent";
import NotFound from "./components/NotFound";

function App() {
  return (
    // <div className="App">
    //     <MainPage/>
    // </div>

    <Router>
      <ThemeProviderComponent>
      <div className="App">
        <Routes>
        <Route path="/" element={<Navigate to="/devices" replace />} />
        <Route path="/devices" element={<MainPage />} />
          <Route path="/home" element={<MainPage />} />
          <Route path="/feedback" element={<FeedbackForm/>} />
          <Route path="/feedbackSuccess" element={<FeedbackSuccessPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      </ThemeProviderComponent>
    </Router>
  );
}

export default App;
