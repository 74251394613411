import * as React from "react";
import { useState, useEffect } from "react";
import FormLabel from "@mui/material/FormLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/system";
import { Box, Button, Container, Grid2, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/FirebaseConfig";
import { useNavigate } from "react-router-dom";

const FormGrid = styled(Grid2)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export default function FeedbackForm() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a language is saved in sessionStorage and apply it
    const savedLanguage = sessionStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  // State to hold form data
  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    email: "",
    subject: "",
    comments: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phoneNo: "",
    email: "",
    subject: "",
    comments: "",
  });

  // Handle change in input fields
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formattedPhoneNo = formData.phoneNo.replace(/\s+/g, '');
      //console.log("Form data submitted:", formData);
      try {
        await setDoc(doc(db, "feedbacks", formattedPhoneNo), {
          fullName: formData.name,
          phoneNo: formattedPhoneNo,
          email: formData.email,
          subject: formData.subject,
          comments: formData.comments,
          timestamp: serverTimestamp(),
        });
        //alert("Feedback submitted successfully!");
        setFormData({
          name: "",
          phoneNo: "",
          email: "",
          subject: "",
          comments: "",
        });
        navigate("/feedbackSuccess");
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  const cleanPhoneNumber = (phone) => {
    return phone.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[1-9][\d\s\-()]{9,20}$/;

    if (!formData.name) newErrors.name = t("Namerequired");
    if (formData.email && !emailPattern.test(formData.email))
      newErrors.email = t("Emailrequired");

    if (!formData.phoneNo) newErrors.name = t("Phonerequired");
    /*
      Phone no validation
        allowed valid number and formats
          1111111111
          111 111 1111
          11111111111
          1 111 111 1111
         +1 111 111 1111
          11111 11111
          91 11111 11111
         +91 11111 11111
    */
    if (formData.phoneNo) {
      const cleanedPhone = cleanPhoneNumber(formData.phoneNo);
      const phoneLength = cleanedPhone.length;

      if (!phonePattern.test(formData.phoneNo)) {
        console.log("fails here at char validation" + cleanedPhone);
        newErrors.phoneNo = t("Phonerequired");
      } else {

          if (phoneLength < 10 || phoneLength > 13) {
            console.log("fails here at 11 validation");
            newErrors.phoneNo = t("Phonerequired");
          }
      }
    }

    if (!formData.subject) newErrors.subject = t("Subjectrequired");
    if (!formData.comments || formData.comments.length < 10)
      newErrors.comments = t("Commentsmin10characters");
    if (!formData.comments || formData.comments.length > 500)
      newErrors.comments = t("Commentsmax500characters");

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 4, sm: 4 },
          py: { xs: 8, sm: 10 },
          textAlign: "left",
        }}
      >
        <Grid2
          container
          spacing={3}
          sx={{ width: "80%", flexDirection: "column" }}
        >
          <FormGrid>
            <FormLabel sx={{ color: "text.primary" }} htmlFor="name" required>
              {t("Name")}
            </FormLabel>
            <OutlinedInput
              id="name"
              name="name"
              type="text"
              placeholder="Rama"
              autoComplete="given-name"
              value={formData.name}
              onChange={handleChange}
              required
              size="small"
              error={!!errors.name}
              //inputProps={{ title: "Please enter your name" }}
            />
            {errors.name && (
              <Typography color="error">{errors.name}</Typography>
            )}
          </FormGrid>

          <FormGrid>
            <FormLabel sx={{ color: "text.primary" }} htmlFor="phoneNo" required>
              {t("PhoneNumber")}
            </FormLabel>
            <OutlinedInput
              id="phoneNo"
              name="phoneNo"
              type="text"
              placeholder="+91 11111 11111"
              autoComplete="mobile"
              value={formData.phoneNo}
              onChange={handleChange}
              required
              size="small"
              error={!!errors.phoneNo}
            />
            {errors.phoneNo && (
              <Typography color="error">{errors.phoneNo}</Typography>
            )}
          </FormGrid>

          <FormGrid>
            <FormLabel sx={{ color: "text.primary" }} htmlFor="email">
              {t("Email")}
            </FormLabel>
            <OutlinedInput
              id="email"
              name="email"
              type="text"
              placeholder="rama@email.com"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              // required
              size="small"
              error={!!errors.email}
            />
            {errors.email && (
              <Typography color="error">{errors.email}</Typography>
            )}
          </FormGrid>
          <FormGrid>
            <FormLabel
              sx={{ color: "text.primary" }}
              htmlFor="subject"
              required
            >
              {t("Subject")}
            </FormLabel>
            <OutlinedInput
              id="subject"
              name="subject"
              type="text"
              placeholder={t("SubjectShort")}
              value={formData.subject}
              onChange={handleChange}
              required
              size="small"
              error={!!errors.subject}
            />
            {errors.subject && (
              <Typography color="error">{errors.subject}</Typography>
            )}
          </FormGrid>
          <FormGrid>
            <FormLabel
              sx={{ color: "text.primary" }}
              htmlFor="comments"
              required
            >
              {t("Comments")}
            </FormLabel>
            <OutlinedInput
              sx={{
                height: {
                  xs: "80px",
                  sm: "80px",
                  md: "80px",
                  lg: "80px",
                },
              }}
              id="comments"
              name="comments"
              type="text"
              placeholder={t("CommentsHere")}
              value={formData.comments}
              onChange={handleChange}
              required
              size="medium"
              fullWidth
              multiline
              rows="3"
              // maxRows={4}
              // minRows={4}
              error={!!errors.comments}
            />
            {errors.comments && (
              <Typography color="error">{errors.comments}</Typography>
            )}
          </FormGrid>
        </Grid2>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            size="small"
            sx={{ flexShrink: 0 }}
          >
            Submit
          </Button>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontSize: "small" }}
          >
            Disclaimer: We are committed to protecting your privacy. We do not
            sell, rent, or lease your personal information to third parties.
            Additionally, your data will not be used for marketing purposes
            without your explicit consent. We ensure that your information is
            handled with the utmost care and in accordance with applicable
            privacy laws and regulations.
          </Typography>
        </Box>
      </Container>
    </form>
  );
}
