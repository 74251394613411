import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

const LaunchingSoon = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPopupVisible(true);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  const handleClose = () => {
    setIsPopupVisible(false);
  };

  return (
    <Modal
      open={isPopupVisible}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: { xs: '50%', sm: '30%' }, // Set width to 30% of the viewport
          height: { xs: '20%', sm: '30%' }, // Set height to 30% of the viewport
          filter: 'brightness(1.5)',
          bgcolor: 'rgba(17, 33, 48, 0.7)',
          backdropFilter: 'blur(5px)',
          boxShadow: '0 0 15px rgba(118, 189, 245, 0.8)',
          borderRadius: 2,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            bgcolor: 'rgba(17, 33, 48, 0.7)',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: 2, position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center', }}>
          <Typography color="white" variant="h6">Launching Soon</Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default LaunchingSoon;
