import * as React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import getMPTheme from "./theme/getMPTheme";
import Logo from "./components/Logo";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
//import ToggleColorMode from "./components/ToggleColorMode";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderBottom: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  backgroundImage: "none",
  padding: 4,
}));

function NavBar({ mode, toggleColorMode }) {
  const MPTheme = createTheme(getMPTheme(mode));
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/"); // Navigate to the Home page
  };

  return (
    <ThemeProvider theme={MPTheme}>
      <StyledAppBar>
        <Container maxWidth="lg">
          <Toolbar
            variant="dense"
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* <SitemarkIcon/> */}
            <IconButton
              onClick={handleNavigateHome}
              aria-label="home"
              sx={{
                p: 0,
                minWidth: 0,
                border: "none",
                background: "none",
                "&:hover": {
                  background: "none",
                },
              }}
            >
              <Logo /> {/* Logo component used inside IconButton */}
            </IconButton>

            {/* <ToggleColorMode
              data-screenshot="toggle-mode"
              mode={mode}
              toggleColorMode={toggleColorMode}
            /> */}
          </Toolbar>
        </Container>
      </StyledAppBar>
    </ThemeProvider>
  );
}

NavBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  showCustomTheme: PropTypes.bool.isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default NavBar;
