import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();
    const handleNavigateHome = () => {
        navigate("/"); // Navigate to the Home page
      };

    return (
        <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 4, sm: 4 },
          py: { xs: 8, sm: 10 },
          textAlign: "left",
          position: "relative",
        }}
      >
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 2 }}
        >
          {/* <SendOutlinedIcon sx={{color:"red"}}  /> */}
          <Typography
            component="h2"
            variant="subtitle1"
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: 'small'
            }}
          >
            Page Not Found
          </Typography>
        </Box>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 2 }}
        >
          <Button
            onClick={handleNavigateHome}
            type="submit"
            variant="outlined"
            color="primary"
            size="small"
            sx={{ flexShrink: 0 }}
          >
            Home
          </Button>
        </Box>
      </Container>
    );
  }


